<template>
  <gql-table
    ref="newsGqlTable"
    :fields="fields"
    :query="query"
    query-type="news"
    :sort-desc="true"
    sort-by="date"
  >
    <template #cell(date)="data">
      {{ formatDateTime(data.item.date) }}
    </template>
    <template #cell(impact)="data">
      <b-badge :variant="impactVariants[data.item.impact]">
        {{ data.item.impact }}
      </b-badge>
    </template>
    <template #cell(restricted)="data">
      <b-badge
        v-if="data.item.restricted"
        variant="light-danger"
      >
        Restricted
      </b-badge>
    </template>
  </gql-table>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { IMPACT_VARIANTS } from '@/enums/news'
import { formatDateTime } from '@/plugins/formaters'

const fields = [
  {
    name: 'title',
    label: 'Title',
    filterable: 'like',
  },
  {
    name: 'country',
    label: 'Country',
    filterable: 'like',
  },
  {
    name: 'date',
    label: 'Date',
    filterable: 'daterange',
    sortable: true,
  },
  {
    name: 'impact',
    label: 'Impact',
    filterable: { type: 'multienum', enum: Object.keys(IMPACT_VARIANTS) },
  },
  {
    name: 'forecast',
    label: 'Forecast',
  },
  {
    name: 'previous',
    label: 'Previous',
  },
  {
    name: 'restricted',
    label: 'Restricted',
    filterable: {
      type: 'is',
    },
    default: true,
  },
]

const query = [
  'id',
  'title',
  'country',
  'date',
  'impact',
  'forecast',
  'previous',
  'restricted',
]
export default {
  components: { GqlTable, BBadge },
  data() {
    return {
      impactVariants: IMPACT_VARIANTS,
      query,
      fields,
    }
  },
  methods: { formatDateTime },
}
</script>
